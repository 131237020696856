import { faBars, faCheck, faPlus, faSearchLocation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { Fragment } from 'react';
import { Alert, Button } from "react-bootstrap";
import { SkullMarker, updateMarker } from "../utils/markers";
import { GeoLocationStatus } from '../utils/utils';
import './App.css';
import { UIState } from './UI';
import { UIComponent } from "./utils";

export const getAlerts = (uiState: UIState, markers: SkullMarker[]): JSX.Element[] => {
    const alerts: JSX.Element[] = [];
    switch (uiState.type) {
        case "default":
            break;
        case "edit":
            break;
        case "move":
            if (!uiState.marker.id) {
                alerts.push(<Alert className="nav-alert" variant="info">
                    Drag the marker to move it
                </Alert>);
            } else {
                alerts.push(<Alert className="nav-alert" variant="info">
                    Drag the marker to move it
                </Alert>);
            }
            break;
    }
    return alerts;
};

export const UiNavigation: UIComponent<{geoLocation: GeoLocationStatus}> = ({ uiState, markers, setUiState, map , geoLocation}) => {
    const alerts = getAlerts(uiState, markers);

    const debug = false;

    return <div id="overlaything">
        <div className="navbar-top">
                <div className="navbar-container" style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        {alerts.map((alert, index) => <Fragment key={index}>{alert}</Fragment>)}
                    {debug ?
                        <div style={{ backgroundColor: "black", color: "white" }}>Current state: <pre>{JSON.stringify(uiState)}</pre></div>
                        : null}
                    </div>
            </div>
        </div>
        <div className="navbar-top">
            <div className="navbar-container">
                <Button variant="primary" onClick={() => setUiState({ type: "list" })}>
                <FontAwesomeIcon icon={faBars}/>
            </Button>
            </div>
        </div>
        <div className="navbar-bottom">
            <div className="navbar-container">
                {uiState.type === "default" ?
                    <>
                        <GeoLocationButton map={map} geoLocation={geoLocation} />
                        <Button className="nav-button" variant="success"
                            onClick={() => setUiState({ type: "move", marker: { position: map.getCenter() } })}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </>
                    : null}
                {uiState.type === "move" ?
                    <>
                        <Button className="nav-button" variant="danger" onClick={() => setUiState({ type: "default" })}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button className="nav-button" variant="primary" onClick={() => {
                            if (uiState.marker.id) {
                                const currentMarker = markers.find((_marker) => _marker.id === uiState.marker.id);
                                if (!currentMarker) return;
                                const marker: SkullMarker = { ...currentMarker, position: uiState.marker.position };
                                updateMarker(marker);
                                setUiState({ type: "default" });
                            } else {
                                setUiState({ type: "edit", marker: { ...uiState.marker } });
                            }
                        }}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </>
                    : null}
            </div>
        </div>
    </div>
};

export const GeoLocationButton: React.FC<{ map: Map, geoLocation: GeoLocationStatus }> = ({ map, geoLocation }) => {
    switch (geoLocation.type) {
        case "success": return <Button className="nav-button" variant="primary" onClick={() => {
            map.panTo(geoLocation.location);
        }}>
            <FontAwesomeIcon icon={faSearchLocation} />
        </Button>;
        case "unknown": return <Button className="nav-button" variant="secondary" onClick={() => {
            navigator.geolocation.getCurrentPosition(() => { return; });
        }}>
            <FontAwesomeIcon className="spinner" icon={faSearchLocation} />
        </Button>;
        default: return null;
    }
};