import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from 'react';
import { Marker } from "react-leaflet";
import { MinimalSkullMarker, SkullMarker } from "../../utils/markers";
import { GeoLocationStatus } from "../../utils/utils";
import '../App.css';
import { UIComponent } from "../utils";
import { personMarker, SkullMapMarker } from "./SkullMapMarker";


const zoomToScale = (zoom: number): number => {
    return zoom < 13 ? 0.3 : (
        1 - 0.7 * ((19 - zoom) / (19 - 13))
    );
}

/**
 * Renders markers, draggable pins, detects click events and reports them to UI
 * @param props 
 * @returns 
 */
export const MarkerManager: UIComponent<{ geoLocation: GeoLocationStatus }> = ({ markers, uiState, setUiState, geoLocation, map }) => {
    const onClickMarker = (marker: MinimalSkullMarker | SkullMarker) => {
        if (uiState.type === "default") {
            setUiState({ type: "edit", marker: { ...marker } });
        }
    };
    const onDoubleClickMarker = (marker: MinimalSkullMarker | SkullMarker) => {
    };
    const onContextMenuMarker = (marker: MinimalSkullMarker | SkullMarker) => {
        setUiState({ type: "move", marker: { ...marker } });
    };
    const onDragMarker = (marker: MinimalSkullMarker | SkullMarker) => {
        if (uiState.type === "move") {
            //api.dragMarker(marker);
            setUiState({ type: "move", marker: { ...marker } });
        }
    };

    const [scale, setScale] = useState(zoomToScale(map.getZoom()));
    useEffect(() => {
        map.on("zoomend", (e) => {
            setScale(zoomToScale(map.getZoom()));
        })
    });
    return <>
        {
            markers.map((marker, index) => {
                const selected = uiState.type === "edit" && uiState.marker.id === marker.id; // or other modes where marker is selected
                const draggable = (uiState.type === "move" && uiState.marker.id && uiState.marker.id === marker.id) ? uiState : false;
                return <SkullMapMarker
                    key={index}
                    onClick={() => onClickMarker(marker)}
                    onDoubleClick={() => onDoubleClickMarker(marker)}
                    onContextMenu={() => onContextMenuMarker(marker)}
                    hideLabel={map.getZoom() < 14}
                    onDrag={onDragMarker}
                    marker={marker}
                    selected={selected}
                    draggable={draggable}
                    scale={scale}
                />
            })

        }
        {(uiState.type === "edit" || uiState.type === "move") && !uiState.marker.id ?
            (() => {
                const position = uiState.marker.position;
                const newMarker: MinimalSkullMarker = { position: position };
                const draggable = uiState.type === "move" && uiState;
                return <SkullMapMarker
                    onClick={() => onClickMarker(newMarker)}
                    onDoubleClick={() => onDoubleClickMarker(newMarker)}
                    onContextMenu={() => onContextMenuMarker(newMarker)}
                    hideLabel={map.getZoom() < 14}
                    onDrag={onDragMarker}
                    marker={{ position: position }}
                    selected={true}
                    draggable={draggable}
                    scale={scale}
                />;
            })()
            : null}
        {geoLocation.type === "success" ? 
            <Marker icon={personMarker} position={geoLocation.location} />
            : null}
    </>;
}