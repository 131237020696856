import { LatLngExpression } from "leaflet";
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from "react-leaflet";
import { MapUI2 } from "./UI";
import { MapWatcher } from "../utils/MapWatcher";
import { GeoLocationStatus, readLS } from "../utils/utils";
import './App.css';

const defaultPosition: LatLngExpression = [49.2402, 6.9969];
const defaultZoom: number = 15;

const errorCodes: ["PERMISSION_DENIED", "POSITION_UNAVAILABLE", "TIMEOUT"] = ["PERMISSION_DENIED", "POSITION_UNAVAILABLE", "TIMEOUT"]

export const KB63Map: React.FC = () => {
    const [position,] = useState<LatLngExpression>(() => readLS("mapCenter", defaultPosition));
    const [zoom,] = useState<number>(() => readLS("zoom", defaultZoom));
    const [geolocation, setGeolocation] = useState<GeoLocationStatus>({ type: "unknown" });

    useEffect(() => {
        console.log("[GEO] registering geo watcher");
        const watchId = navigator.geolocation.watchPosition((update) => {
            setGeolocation({ type: "success", location: [update.coords.latitude, update.coords.longitude] });
        }, (error) => {
            console.warn("[GEO] geo watcher error'ed:", error);
            const reason = errorCodes[error.code];
            if (!reason) return;
            setGeolocation({ type: "error", reason: reason })
        });
        return () => {
            console.log("[Geo] unregistering geo watcher");
            navigator.geolocation.clearWatch(watchId);
        }
    }, []);

    return <div style={{ top: 0, bottom: 0, position: "absolute", width: "100%" }}>
        <MapContainer
            style={{ height: "100%" }}
            center={position}
            zoom={zoom}
            scrollWheelZoom={true}
        >

            <TileLayer
                attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={19}
            />
            <MapUI2 geoLocation={geolocation} />
            <MapWatcher />
        </MapContainer>
    </div >
};

export default KB63Map;