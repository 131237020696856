import { LatLngTuple, Map } from "leaflet";
import { SkullMarker, SkullMarkerID } from "./markers";

export const wrapLSWrite = <T,>(lsKey: string, setValue: (t:T)=> void) => {
    return (t: T) => {
        localStorage.setItem(lsKey, JSON.stringify(t));
        setValue(t);
    };
};

export const readLS = <T,>(lsKey: string, defaultValue: T) => {
    const entry: string | null = localStorage.getItem(lsKey);
    if (entry === null) return defaultValue;
    return JSON.parse(entry) as T;
};

export const updateUiState = (map: Map) => {
    localStorage.setItem("mapCenter", JSON.stringify(map.getCenter()));
    localStorage.setItem("zoom", JSON.stringify(map.getZoom()));
};

export const getMarkerById = (markers: SkullMarker[], markerId: SkullMarkerID) => {
    return markers.find(_marker => _marker.id === markerId);
};

export type GeoLocationStatus = {
    type: "success",
    location: LatLngTuple
} | {
    type: "unknown"
} | {
    type: "error",
    reason: "PERMISSION_DENIED" | "POSITION_UNAVAILABLE" | "TIMEOUT"
}