import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from 'react';
import { Marker } from "react-leaflet";
import { AnySkullMarker, MinimalSkullMarker, SkullMarker } from "../../utils/markers";
import '../App.css';
import { UIState } from "../UI";

export const SkullMapMarker: React.FC<{
    onClick: () => void,
    onDoubleClick: () => void,
    onContextMenu: () => void,
    marker: AnySkullMarker,
    selected: boolean,
    draggable: false | UIState & { type: "move", marker: AnySkullMarker }
    onDrag: (skull: MinimalSkullMarker | SkullMarker) => void,
    hideLabel: boolean,
    scale: number,
    highlighted?: boolean
}> = (props) => {
    return <Marker
        eventHandlers={{
            click: () => props.onClick(),
            dblclick: () => {
                //props.setUiState({ type: "edit", marker: { type: "marker", id: props.marker.id } })
                props.onDoubleClick();
            },
            contextmenu: () => {
                // also called on touch long press
                props.onContextMenu();
                //props.setUiState({ type: "move", marker: { type: "marker", id: props.marker.id, position: props.marker.position } })
            },
            dragend: (e) =>{
                const position = (e.target as any)._latlng as LatLngExpression;
                props.onDrag({ ...props.marker, position: position });
            }
        }}
        draggable={!!props.draggable}
        icon={
            props.draggable ? moveMarkerIcon(props.hideLabel ? "" : (props.marker.title ?? "")) : markerIcon(props.hideLabel ? "" : (props.marker.title ?? ""), props.scale)
        }
        position={props.draggable !== false ? props.draggable.marker.position : props.marker.position}
    />
};


export const SkullMapPin: React.FC<{
    onMove: (position: LatLngExpression) => void,
    position: LatLngExpression,
}> = (props) => {
    return <Marker
        eventHandlers={{
            dragend: (e) => {
                props.onMove(e.target._latlng);
            },
        }}
        icon={moveMarkerIcon("")}
        position={props.position}
        draggable
    />
};

export const personMarker = L.divIcon({
    html: `<div class="inner-circle"></div>`,
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    className: "person-marker"
});

export const markerIcon = (title: string, scale?: number) : L.DivIcon => L.divIcon({
    html: title ? `<div class="skull-marker-label-container"> <div class="skull-marker-label" > #${title }. </div> </div>` : "" , // Hello, Mr XSS
    className: "my-marker",
    iconSize: [57 * (scale ?? 1), 96 * (scale ?? 1)],
    iconAnchor: [28 * (scale ?? 1), 96 * (scale ?? 1)],
    popupAnchor: [0, 0]
});


export const moveMarkerIcon = (title: string): L.DivIcon => L.divIcon({
    html: title ? `<div class="skull-marker-label-container skull-marker-label-container-move"> <div class="skull-marker-label" > #${title }. </div> </div>` : "",
    className: "my-marker-move",
    iconSize: [56, 147],
    iconAnchor: [30, 145],
    popupAnchor: [0, 0]
});