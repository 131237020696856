import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import './App.css';
import { UIComponent } from "./utils";

export const MenuModal: UIComponent<{}> = ({ uiState, markers, setUiState }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (uiState.type === "menu") {
            setShow(true);
        } else {
            setShow(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiState.type]);

    const saveModal = () => {
        setShow(false);
    };

    return <Modal
        onExited={() => { if (uiState.type === "menu") setUiState({ type: "default" }) }}
        className="menu"
        show={show}
        onHide={() => {
            saveModal();
            setShow(false);
        }} >

        {uiState.type === "menu" ?
            <>
                <Modal.Body className="menu">
                    <Button
                        variant="primary"
                        onClick={() => setUiState({ type: "list" })}
                    >
                        Skull List</Button>
                </Modal.Body>
            </>
            : null}
    </Modal>;
}