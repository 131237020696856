import { LatLng, LatLngExpression, LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import skullPng from '../img/skull_96.png';
import { SkullMarker } from "../utils/markers";
import './App.css';
import { UIComponent } from "./utils";


type SortingFunction = "date" | "number" | "distance";

export const ListModal: UIComponent<{}> = ({ uiState, markers, setUiState , geoLocation, map}) => {
    const [show, setShow] = useState(false);

    const [displayedMarkers, setDisplayedMarkers] = useState<SkullMarker[]>(markers);

    const [sortingFunction, setSortingFunction] = useState<SortingFunction>(geoLocation.type === "success" ? "distance" : "number");

    const sortedMarkers = (markers: SkullMarker[]) => {
        let compareFunction: (a: SkullMarker, b: SkullMarker) => number;
        switch (sortingFunction) {
            case "date":
                compareFunction = (a, b) => (a.date && b.date ? -(a.date.getTime() - b.date.getTime()) : 0);
                break;
            case "number":
                compareFunction = (a, b) => {
                    const aValue = parseInt(a.title);
                    const bValue = parseInt(b.title);
                    if (isFinite(aValue) && isFinite(bValue)) {
                        console.log("comparing", a, "and", b)
                        return -(bValue - aValue);
                    } else if (isFinite(aValue)) {
                        return -1;
                        //a.id.localeCompare(b.id);
                    } else if (isFinite(bValue)) {
                        return 1;
                    } else {
                        return a.id.localeCompare(b.id);
                    }
                };
                break;
            case "distance":
                if (geoLocation.type === "success") {
                    compareFunction = (a, b) => distanceToMarker(geoLocation.location, a.position) - distanceToMarker(geoLocation.location, b.position);
                } else {
                    compareFunction = (a, b) => a.id.localeCompare(b.id);
                }
                break;
        }
        console.log("sorting function was set to", sortingFunction);
        return [...markers].sort(compareFunction);
    };

    useEffect(() => {
        setDisplayedMarkers(sortedMarkers(markers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers, sortingFunction, geoLocation.type]);


    useEffect(() => {
        if (uiState.type === "list") {
            setShow(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiState.type]);

    const saveModal = () => {
        setShow(false);
    };

    const showDistance = (distance: number | undefined): string | undefined => {
        if (distance === undefined) return undefined;
        if (distance < 1000) {
            return `${Math.round(distance)} m`;
        } else {
            return `${(distance/1000).toFixed(1)} km`;
        }
    };

    const distanceToMarker = (location: LatLngTuple, position: LatLngExpression): number => {
        return new LatLng(...location).distanceTo(position);
    }

    const computeDistance = (position: LatLngExpression): number | undefined => {
        if (geoLocation.type === "success") {
            return new LatLng(...geoLocation.location).distanceTo(position);
        } else {
            return undefined;
        }
    }


    return <Modal
        onExited={() => {
            if (uiState.type==="list")
                setUiState({ type: "default" })
        }}
        show={show}
        onHide={() => {
            saveModal();
            setShow(false);
        }} >

        {uiState.type === "list" ?
            <>
                <Modal.Header>
                    <h2>Skull List</h2>
                    <span className="muted tiny">Showing {markers.length} Skulls</span>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <span style={{ marginRight: 10 }}>Sort by</span>
                        <ButtonGroup >
                            <Button variant={sortingFunction==="number" ? "primary" : "outline-primary"} size="sm" onClick={() => setSortingFunction("number")}>number</Button>
                            <Button variant={sortingFunction==="date" ? "primary" : "outline-primary"} size="sm" onClick={() => setSortingFunction("date")}>date</Button>
                            {geoLocation.type === "success" ?
                                <Button variant={sortingFunction==="distance" ? "primary" : "outline-primary"} size="sm" onClick={() => setSortingFunction("distance")}>distance</Button>
                                : null}
                        </ButtonGroup>
                    </div>

                    <div className="skull-list">
                        {displayedMarkers.map((marker, index) => (
                            <div onClick={() => {
                                setShow(false);
                                map.setView(marker.position, 19);
                            }} className="skull-list-entry" key={index}>
                                <img style={{ gridArea: "a", height: "50px" }} src={skullPng} alt="skull.png" />
                                <div style={{ gridArea: "c" }}>
                                    {marker.title ?
                                        <b>{marker.title} </b>
                                        : <span>(No title given) </span>
                                    }
                                </div>
                                <div style={{ gridArea: "d" }}>
                                    {
                                        geoLocation.type === "success" ?
                                            <span className="muted">
                                                {showDistance(computeDistance(marker.position)) || ""}
                                            </span>
                                            : null
                                    }
                                </div>
                                <div className="skull-list-notes" style={{ gridArea: "b" }}>
                                    {marker.notes || ""}
                                </div>
                            </div>)
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </>
            : null}
    </Modal>;
}