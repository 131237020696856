import { LatLngExpression } from "leaflet";
import { v4 as uuid4 } from 'uuid';

const handlers: Set<(newMarkers: SkullMarker[]) => void> = new Set();

export type AnySkullMarker = SkullMarker | MinimalSkullMarker;

export type SkullMarkerID = string;

export type SkullMarker = {
    position: LatLngExpression,
    id: SkullMarkerID,
    notes: string,
    title: string,
    illegible?: boolean,
    date?: Date
};

export type MinimalSkullMarker = {
    position: LatLngExpression
} & { [property in keyof SkullMarker]?: SkullMarker[property] };

const genUUID = (): SkullMarkerID => {
    const ids = new Set(getMarkers().map(marker => marker.id));
    let newUuid: string;
    do {
        newUuid = uuid4();
    } while (ids.has(newUuid));
    return newUuid;
}

export const getMarkers = () => {
    const value = localStorage.getItem("markers");
    if (!value) return [];
    return JSON.parse(value) as SkullMarker[];
};

export const updateMarker = (marker: MinimalSkullMarker): SkullMarker => {
    const newMarker: SkullMarker = {
        position: marker.position,
        title: marker.title ?? "",
        notes: marker.notes ?? "",
        id: marker.id ?? genUUID(),
    }
    // filter out duplicates by id
    const legitMarkers = getMarkers().filter(_marker => _marker.id !== newMarker.id);
    const newMarkers = [newMarker, ...legitMarkers];
    writeMarkers(newMarkers);
    emitUpdate(newMarkers);
    return newMarker;
};

//export const updateMarker = (marker: SkullMarker) => {
//    const legitMarkers = getMarkers().filter(_marker => _marker.id !== marker.id);
//    const newMarkers = [marker, ...legitMarkers];
//    writeMarkers(newMarkers);
//    emitUpdate(newMarkers);
//};

export const removeMarker = (markerToRemove: { id: string }) => {
    const newMarkers = getMarkers().filter(_marker => _marker.id !== markerToRemove.id);
    writeMarkers(newMarkers);
    emitUpdate(newMarkers);
    return newMarkers;
};

const writeMarkers = (markers: SkullMarker[]) => {
    localStorage.setItem("markers", JSON.stringify(markers));
};

const emitUpdate = (newMarkers: SkullMarker[]) => {
    handlers.forEach(handler => handler(newMarkers));
};

export const markerUpdates = {
    subscribe: (handler: (newMarkers: SkullMarker[]) => void) => {
        handlers.add(handler);
    },
    unsubscribe: (handler: (newMarkers: SkullMarker[]) => void) => {
        handlers.delete(handler);
    }
};