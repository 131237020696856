import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from 'react';
import { useMapEvents } from "react-leaflet";
import { AnySkullMarker, getMarkers, markerUpdates, SkullMarker, updateMarker } from '../utils/markers';
import { GeoLocationStatus, updateUiState } from "../utils/utils";
import './App.css';
import { MarkerManager } from "./markers/MarkerManager";
import { EditModal } from "./EditModal";
import { UiNavigation } from './UiNavigation';
import { MenuModal } from "./MenuModal";
import { ListModal } from "./ListModal";


export type UIState = {
    type: "default"
} | {
    type: "move",
    marker: AnySkullMarker
} | {
    type: "edit"
    marker: AnySkullMarker
} | {
    type: "menu"
} | {
    type: "list"
};

export const MapUI2: React.FC<{ geoLocation: GeoLocationStatus }> = ({ geoLocation }) => {
    const [uiState, setUiState] = useState<UIState>({ type: "default" });
    const [markers, setMarkers] = useState<SkullMarker[]>(getMarkers());
    useEffect(() => {
        markerUpdates.subscribe(markers => {
            setMarkers([...markers]);
        });
        return markerUpdates.unsubscribe(setMarkers);
    }, []);

    const map = useMapEvents({
        move: (): void => {
            updateUiState(map);
        },
        contextmenu: (x) => {
            const target = x.originalEvent.target as any;
            // dirty hack because leaflet click events seem to be on the wrong layer(?)
            if (target.classList.contains("leaflet-container")) {
                if (uiState.type === "default") {
                    const position = x.latlng;
                    setUiState({ type: "move", marker: { position: position } })
                }            }
        },
        click: (x) => {
            const target = x.originalEvent.target as any;
            // dirty hack because leaflet click events seem to be on the wrong layer(?)
            if (target.classList.contains("leaflet-container")) {
                // clicking on the map
                if (uiState.type === "move") {
                    let id: string | undefined = uiState.marker.id;
                    if (id) {
                        const currentMarker = markers.find((_marker) => _marker.id === id);
                        if (!currentMarker) return;
                        const marker: SkullMarker = { ...currentMarker, position: uiState.marker.position };
                        updateMarker(marker);
                        setUiState({ type: "default" });
                    } else {
                        setUiState({ type: "edit", marker: { position: uiState.marker.position } });
                    }
                }
            }
        }
    })

    return <>
        <MarkerManager map={map} setUiState={setUiState} uiState={uiState} markers={markers} geoLocation={geoLocation} />
        <UiNavigation  map={map} setUiState={setUiState} uiState={uiState} markers={markers} geoLocation={geoLocation} />
        <EditModal  map={map} setUiState={setUiState} uiState={uiState} markers={markers} geoLocation={geoLocation} />
        <MenuModal  map={map} setUiState={setUiState} uiState={uiState} markers={markers} geoLocation={geoLocation} />
        <ListModal  map={map} setUiState={setUiState} uiState={uiState} markers={markers} geoLocation={geoLocation} />
    </>;
};