import "leaflet/dist/leaflet.css";
import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { MinimalSkullMarker, removeMarker, updateMarker } from "../utils/markers";
import './App.css';
import { UIComponent } from "./utils";

export const EditModal: UIComponent<{}> = ({ uiState, markers, setUiState }) => {
    const [show, setShow] = useState(false);
    const [editTitle, setEditTitle] = useState<string>();
    const [editNotes, setEditNotes] = useState<string>();

    useEffect(() => {
        if (uiState.type === "edit") {
            const currentMarker = markers.find((_marker) => _marker.id === uiState.marker.id);
            if (currentMarker) {
                setEditTitle(currentMarker.title ?? "");
                setEditNotes(currentMarker.notes ?? "");
            } else {
                setEditTitle("");
                setEditNotes("");
            }
            setShow(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiState.type]);

    const saveModal = () => {
        if (uiState.type !== "edit") return;
        let marker: MinimalSkullMarker;
        if (!uiState.marker.id) {
            marker = {
                position: uiState.marker.position,
                title: editTitle,
                notes: editNotes,
            };
        } else {
            const markerId = uiState.marker.id;
            const currentMarker = markers.find(_marker => _marker.id === markerId);
            if (!currentMarker) return;
            marker = { ...currentMarker, title: editTitle ?? "", notes: editNotes ?? "" };
        }
        updateMarker(marker);
        setShow(false);
    };

    const deleteMarker = () => {
        if (uiState.type !== "edit") return;
        if (uiState.marker.id) {
            removeMarker({ id: uiState.marker.id });
        }
        setShow(false);
    };

    const onKeyPress : KeyboardEventHandler = (e) => {
        if ((e.target as any).nodeName === "TEXTAREA") return;
        if (e.key === "Enter") {
            saveModal();
            setShow(false);
        }
    };

    return <Modal
        onExited={() => setUiState({ type: "default" })}
        show={show}
        onHide={() => {
            saveModal();
            setShow(false);
        }} >
        {uiState.type === "edit" ?
            <>
                <Modal.Body>
                    <Form onKeyPress={onKeyPress}>
                        <Form.Group className="mb-3" controlId="formGroupTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                type="text"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.currentTarget.value)}
                            />
                            <Form.Text muted>
                                The title will be shown on the map
                            </Form.Text>
                        </Form.Group>
                        <Form.Group onKeyPress={() => {}} className="mb-3" controlId="formGroupNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                value={editNotes}
                                onChange={(e) => setEditNotes(e.currentTarget.value)}
                                as="textarea"
                                rows={2}
                            />
                            <Form.Text muted>
                                Space for extra notes
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <div>
                        {uiState.marker.id ?
                            <Button variant="danger" onClick={deleteMarker} > Delete </Button>
                            : <Button variant="danger" onClick={deleteMarker} > Cancel </Button>
                        }
                    </div>
                    <div>
                        <Button style={{ marginLeft: "0.25rem" }} variant="primary" onClick={saveModal}>
                            Save
                        </Button>
                    </div>
                </Modal.Footer>
            </>
            : null}
    </Modal>;
}