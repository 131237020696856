import { useMapEvents } from "react-leaflet";

export const MapWatcher: React.FC = (props) => {
    const map = useMapEvents({
        move: () => {
            localStorage.setItem("mapCenter", JSON.stringify(map.getCenter()));
            localStorage.setItem("zoom", JSON.stringify(map.getZoom()));
        }
    });

    return null;
};